#marketing-unsubscribe {
    background-color: white;
}

#marketing-unsubscribe a {
    color: rgba(255, 119, 0, 0.8);
    transition: 0.8s;
}
#marketing-unsubscribe a:hover {
    color: rgba(255, 119, 0, 0.5);
}

#marketing-unsubscribe h1 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-top: 34px;
    text-align: center;
}

#marketing-unsubscribe .info-text {
    text-align: center;
    font-size: 16px;
}

#marketing-unsubscribe .info-text-bottom {
    text-align: left;
    hyphens: auto;
}

#marketing-unsubscribe .waiver {
    text-align: center;
}

#marketing-unsubscribe p.waiver {
    font-size: 14px !important;
    opacity: 0.6;
}

#marketing-unsubscribe p.waiver a {
    opacity: 1;
    font-weight: 800;
}
